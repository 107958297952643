import "./App.css";
import Home from "./Home";
import { useState } from "react";
import { auth } from "./firebaseconfig";
import Login from "./Login";
import { onAuthStateChanged } from "firebase/auth";

function App() {
  const [user, setUser] = useState();

  onAuthStateChanged(auth, (user) => {
    setUser(user);
  });

  return <div className="App">{!auth.currentUser ? <Login /> : <Home />}</div>;
}

export default App;
