import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import Redirect from "./Redirect";
import User from "./User";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/:user" exact element={<User />} />
      <Route path="/:user/:id" exact element={<Redirect />} />
      <Route path="/" exact element={<App />} />
    </Routes>
  </BrowserRouter>
);
