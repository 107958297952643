import { auth } from "./firebaseconfig";
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import GoogleButton from "react-google-button";

const provider = new GoogleAuthProvider();

const authorizedEmails = [
  "nibor.aerts@gmail.com",
  "youri.aerts@gmail.com",
  "jnsaerts@gmail.com",
  "ispoormans@gmail.com",
  "matthi16028@gmail.com",
];

function Login() {
  const signinWithGoogle = async () => {
    const result = await signInWithPopup(auth, provider);
    const userEmail = result.user.email;
    if (!authorizedEmails.includes(userEmail)) auth.signOut();
  };

  const loginWithEmail = async (e) => {
    e.preventDefault();
    const res = await signInWithEmailAndPassword(auth, e.target.email.value, e.target.password.value);
    const userEmail = res.user.email;
    if (!authorizedEmails.includes(userEmail)) auth.signOut();
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1>URL redirecter</h1>
      <p>(Only for authorized users)</p>
      <div id="login-methods">
      <GoogleButton style={{ marginTop: "2rem", marginBottom: "3rem" }} onClick={signinWithGoogle} />
      <hr/>
      <form style={{display: "flex", flexDirection: "column", marginTop: "3rem"}} onSubmit={(e) => loginWithEmail(e)}>
      <input placeholder="Email..." className="input-box" name="email" type="text"></input>
      <input placeholder="Password..." className="input-box" name="password" type="password"></input>
      <input className="add-redirect-btn" type="submit" value="Login"/>
      </form>
      </div>
    </div>
  );
}

export default Login;
